.cd-diagram-card-bottomline {
  display: flex;
  margin-bottom: -10px;

  .__item {
    cursor: default;
    flex: 0 0 auto;
    padding: 0 11px 0 0;
    position: relative;

    + .__item {
      padding-left: 11px;

      &::before {
        border-color: inherit;
        border-width: 0 0 0 1px;
        border-style: solid;
        content: "";
        display: block;
        height: 16px;
        left: 0;
        position: absolute;
        top: 3px;
        width: 1px;
      }
    }

    &._clickable {
      cursor: pointer;
    }
  }

  .__text {
    border-width: 0 0 1px 0;
    border-style: dotted;
  }
}
